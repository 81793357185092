import API from "../utils/axios";
import { getTokenFromLocalStorage } from "../utils/local-storage";

var headers;

export const updateAccount = async (data, id) => {
  const authExist = getTokenFromLocalStorage();

  if (authExist != "") {
    headers = {
      "Content-Type": "application/json",
      authorization: `Token ${authExist} `,
    };
  }
  const promsiecheck = new Promise((res, rej) => {
    API.put(
      `/users/${id}`,
      {
        first_name: data.first_name,
        last_name: data.last_name,
      },
      {
        headers,
      }
    )
      .then((response) => {
        res(response);
      })
      .catch((err) => {
        res(err);
      });
  });

  const final_data = await promsiecheck;
  return final_data;
};
