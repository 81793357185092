import React, { useState, useEffect } from "react";
import Authheader from "../layout/header/autheader";
import { Link } from "gatsby";
import { Formik } from "formik";
import { updateAccount } from "../../api/actions/updateAccount.js";
import "../login/assets/css/styles.scss";

export default function Account() {
  const [userdata, setuserdata] = useState({});
  const [customError, setCustomError] = useState("");
  const [customSuccess, setCustomSecuss] = useState("");
  const [loading, isloading] = useState(false);

  useEffect(() => {
    if (localStorage.user) {
      setuserdata(JSON.parse(localStorage.user));
    }
  }, []);

  return (
    <div>
      <Authheader />
      <div className="login-form account-edit">
        <div className="signup  form">
          <h1>Edit Account</h1>
          <h2>ACCOUNT</h2>
          {userdata.id && (
            <Formik
              initialValues={{
                first_name: userdata.first_name,
                last_name: userdata.last_name,
                id: userdata.id,
              }}
              validate={(values) => {
                const errors = {};

                if (!values.last_name) {
                  errors.last_name = "Required";
                }

                if (!values.first_name) {
                  errors.first_name = "Required";
                }

                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                //if form is updated without changing fields
                if (
                  values.first_name === userdata.first_name &&
                  values.last_name === userdata.last_name
                ) {
                  setCustomError("Kindly update any feild first.");
                  setTimeout(() => {
                    setCustomError("");
                  }, 5000);

                  return;
                }

                //update form
                isloading(true);
                const result = await updateAccount(values, userdata.id);
                console.log(result);

                //success or failure check
                if (!!result.data) {
                  setCustomSecuss("Account is updated successfully.");

                  //update local storage
                  localStorage.setItem("user", JSON.stringify(values));

                  isloading(false);
                  setTimeout(() => {
                    setCustomSecuss("");
                  }, 5000);
                } else {
                  setCustomError("Something Went Wrong. Kindly try again.");
                  isloading(false);
                  setTimeout(() => {
                    setCustomError("");
                  }, 3000);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="formgroup form-group">
                    <label for=" FIRST NAME">
                      FIRST NAME
                      <span>
                        {errors.first_name &&
                          touched.first_name &&
                          errors.first_name}
                      </span>
                    </label>
                    <input
                      name="first_name"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.first_name}
                      className="form-control "
                      value={values.first_name}
                    />
                    <label>
                      LAST NAME
                      <span>
                        {errors.last_name &&
                          touched.last_name &&
                          errors.last_name}
                      </span>
                    </label>
                    <input
                      name="last_name"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.last_name}
                      className="form-control "
                      value={values.last_name}
                    />
                    <div className="error-custom">
                      {" "}
                      {!!customError && customError}
                    </div>
                    <div className="sucess-custom">
                      {!!customSuccess && customSuccess}
                    </div>
                    <button type="submit" class="next">
                      {loading ? "Updating..." : " Update"}
                    </button>
                    <h3>
                      <Link to="/account/update_password"> Change password</Link>
                    </h3>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
}
